<template>
  <div class="containerFull">
    <div class="banner">
      <img src="../../assets/image/qydtBanner.png" alt />
    </div>
    <div class="container" style="padding:40px">
      <div class="title">{{ data.title }}</div>
      <div class="time">{{ data.creationTime }}</div>
      <div class="con" v-html="data.content"></div>
    </div>
  </div>
</template>

<script>
import { sysNewsGetById } from '../../api/enterpriseNews.js'
export default {
  data() {
    return {
      id: '',
      data: {}
    }
  },
  created() {
    console.log('id',this.$route.query.row)
    this.id = this.$route.query.row
    this.sysNewsGetById(this.id)
  },
  methods: {
    sysNewsGetById(id) {
      sysNewsGetById(id).then((res) => {
        console.log(res.data)
        if (res.data) {
          this.data = res.data
        }
      })
    }
  }
}
</script>

<style scoped>
.containerFull {
  width: 100%;
  margin-bottom: 40px;
}
.banner {
  width: 100%;
  height: 308px;
  margin-bottom: 40px;
}
.banner img {
  width: 100%;
  height: 100%;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #00041a;
  opacity: 1;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 26px;
}
.time {
  font-size: 20px;
  font-family: Segoe UI;
  font-weight: 400;
  color: #707070;
  opacity: 1;
  text-align: center;
  margin-bottom: 47px;
}
.con {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #00041a;
  opacity: 1;
}
</style>
